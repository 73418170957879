"use client";

import Image from "next/image";
import React, { Children, useMemo, useState } from "react";
import { signOut } from "next-auth/react";
import { useRouter } from "next/navigation";

import logo from "../../public/logo.png";
import { DockItem } from "./components/dock-item/dock-item";
import { NotificationsModal } from "./components/notifications-modal/notifications-modal";
import {
  panelNavigationFooter,
  panelNavigationMain,
  membersNavigationMain,
  membersNavigationFooter,
} from "./routes";
import { CollapsibleDockItem } from "./components/collapsible-dock-item/collapsible-dock-item";
import { useUser } from "@aprosoja/stores";

type DockProps = {
  isMembersPanel?: boolean;
};

const Dock = ({ isMembersPanel }: DockProps) => {
  const router = useRouter();
  const [openNotification, setOpenNotification] = useState<boolean>(false);
  const { user } = useUser();

  const paymentIsCancelledOrPending =
    user?.role === "member" &&
    (user.paymentStatus === "CANCELED" || user?.paymentStatus === "PENDING");

  const handleNavigationPath = (path: string) => {
    if (paymentIsCancelledOrPending && path == "/informes") {
      router.push("/associacao");
    } else {
      router.push(path);
    }
  };

  const routes = useMemo(() => {
    if (isMembersPanel) {
      return { main: membersNavigationMain, footer: membersNavigationFooter };
    }

    return { main: panelNavigationMain, footer: panelNavigationFooter };
  }, [isMembersPanel]);

  return (
    <>
      <div
        className={`relative flex-shrink-0 overflow-hidden h-screen max-w-[230px] w-[230px] py-4 flex flex-col
          items-center justify-between bg-th-color-neutral-100 select-none border-r-2 border-th-color-neutral-200 z-20`}
      >
        <div className="h-full flex flex-col pl-4">
          <Image
            src={logo}
            alt="Logo Aprosoja"
            width={174}
            height={51}
            priority
          />
          <div
            className={`flex flex-col justify-start gap-1 w-full h-full
            overflow-auto overflow-x-hidden py-size-x6 pr-8`}
          >
            {Children.toArray(
              routes.main.map((item, index) =>
                item.subItems ? (
                  <CollapsibleDockItem
                    key={`${item.path}-${index}`}
                    {...item}
                    subItems={item.subItems}
                  />
                ) : (
                  <DockItem
                    key={`${item.path}-${index}`}
                    {...item}
                    className={`${item.path === "/informes" && paymentIsCancelledOrPending ? "cursor-not-allowed" : ""}`}
                    onClick={() => handleNavigationPath(item.path)}
                  />
                )
              )
            )}
          </div>
          <div
            className={`w-full flex flex-col gap-size-x2 py-2 pr-5 bg-th-color-neutral-100
            border-t-2 border-th-color-neutral-200 justify-self-end`}
          >
            {isMembersPanel && (
              <DockItem
                label="Notificações"
                onClick={() => setOpenNotification(!openNotification)}
                iconLeft={"notifications"}
                active={openNotification}
              />
            )}

            {Children.toArray(
              routes.footer.map((item) => (
                <DockItem
                  key={item.path}
                  {...item}
                  onClick={() => router.push(item.path)}
                  iconLeft={item.icon}
                />
              ))
            )}
            <DockItem
              iconLeft="logout"
              label="Sair"
              iconColor="th-error"
              className={`w-full h-12 px-1 rounded-xl flex items-center
            text-th-error hover:bg-th-color-neutral-200 hover:cursor-pointer`}
              onClick={() => signOut()}
            />
          </div>
        </div>
      </div>
      {openNotification && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-10 transition-all ease-in-out duration-1000"
          onClick={() => setOpenNotification(false)}
        />
      )}
      <NotificationsModal
        isOpen={openNotification}
        onClose={setOpenNotification}
      />
    </>
  );
};

export { Dock };
